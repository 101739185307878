.other-projects {
  margin-top: 50px;
  transition: 300ms ease-in-out;
}

@media (min-width: 640px) {
  .other-projects {
    margin-top: 100px;
  }
}

@media (min-width: 768px) {
  .other-projects {
    margin-top: 200px;
  }
}

.other-projects h3 {
  font-size: 22pt;
  text-align: center;
}

.project-card-grid {
  list-style: none;
  padding: 0px;
  margin: 20px 0px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  row-gap: 15px;
  column-gap: 15px;
  position: relative;
}

.project-card {
  height: 400px;
  position: relative;
  transition: transform 300ms ease-in, box-shadow 300ms ease-in;
  background-color: #112240;
  padding: 20px;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .project-card-grid {
    margin: 50px 0px 0px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    row-gap: 30px;
    column-gap: 30px;
  }

  .project-card {
    padding: 1rem;
    padding: 30px;
  }

  .project-card:hover {
    transform: translateY(-20px);
  }
}

.project-card-title a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease-in-out;
  font-size: 18pt;
}

.project-card-title h3 {
  margin-bottom: 20px;
}

.project-card-title a:hover {
  color: var(--buttonColor);
}

.card-links {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: rows;
  padding-bottom: 10px;
  padding-right: 10px;
  z-index: 100;
}

.multi-links {
  flex-direction: column;
}

.project-links-inner {
  display: inline-flex;
  justify-content: right;
}

.project-links-inner h4 {
  margin-right: 10px;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  font-size: 14pt;
}
.project-links-inner h4:hover {
  color: var(--buttonColor);
}

.hide-project-link-icon {
  cursor: default;
}
.hide-project-link-icon path {
  fill: transparent;
}

.more-projects {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-weight: bold;
}

.more-projects-button {
  cursor: pointer;
  border-radius: 5px;
  border: none;
  appearance: none;
  user-select: none;
  background: none;
  text-decoration: none;
  height: auto;
  width: auto;
  padding-inline: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  border: 3px solid var(--buttonColor);
  transition: all 0.35s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-projects-button:hover {
  background: var(--buttonColor);
  color: black;
}
