.skills {
  position: relative;
  margin-top: 200px;
  padding-top: 8rem;
  color: var(--textColor);
}

.skills h1 {
  text-align: center;
  font-size: 36pt;
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  row-gap: 50px;
  width: 100%;
}

.grid-item h3 {
  color: var(--buttonColor);
  font-size: 22pt;
  padding-bottom: 10px;
}

.grid-item {
  font-size: 14pt;
  text-align: left;
  line-height: 1;
  font-weight: 500;
}

@media (min-width: 900px) {
  .grid-item {
    line-height: 2;
    font-size: 18pt;
  }
}
