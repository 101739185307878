.hamburger-menu {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  width: 70px;
  border: 5px solid transparent;
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
}

.bar {
  position: relative;
  height: 3px;
  width: 35px;
  display: block;
  margin: 3px auto;
  background-color: white;
  border-radius: 10px;
  transition: 0.4s;
}

.hamburger-menu .bar {
  transition: transform opacity 0.5s ease-out;
}

.hamburger-menu.is-active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-menu.is-active .bar:nth-child(2) {
  transform: translateX(-50px);
  opacity: 0;
}

.hamburger-menu.is-active .bar:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

@media only screen and (max-width: 1000px) {
  .hamburger-menu {
    display: flex;
  }
}
