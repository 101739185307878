.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  margin: 1rem 0px;
}

.footer p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: white;
}
