:root {
  /* Current scroll bar colors */
  --scrollBarColor: #777e90;
  --scrollBarColorHover: #b1b5c3;
  --scrollBarTrackColor: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html::-webkit-scrollbar {
  width: 5px;
}

html::-webkit-scrollbar-track {
  background: var(--scrollBarTrackColor);
}

html::-webkit-scrollbar-thumb {
  background: var(--scrollBarColor);
  border-radius: 100vw;
}

html::-webkit-scrollbar-thumb:hover {
  background: var(--scrollBarColorHover);
}

@supports (scrollbar-color: gray lightgray) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollBarColor) var(--scrollBarTrackColor);
  }
}
