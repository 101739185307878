.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.button-div button {
  width: 180px;
  height: 50px;
}

.invalid-feedback {
  font-size: 1em;
  font-weight: 600;
}

.form-control,
.form-control:focus {
  background: #222f43;
  color: white !important;
}

.form-control {
  border: none;
  transition: box-shadow 300ms ease-in-out;
}

.form-control:hover {
  box-shadow: 0 0 0 0.25rem rgb(96 96 96 / 50%) !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(160, 159, 159) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 50%) !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 50%) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgb(220 53 69) !important;
}

.form-control.is-valid,
.was-validated .form-control:valid:focus {
  box-shadow: 0 0 0 0.25rem rgb(25 135 84) !important;
}

.input-container {
  padding: 3px;
  overflow: auto;
}

.form-control:focus {
  box-shadow: none;
}

.inline-details {
  margin-inline: auto;
  column-gap: 20px;
}

.row > * {
  padding-right: 0;
  padding-left: 0;
}

form input {
  height: 50px;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

form * {
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

form textarea {
  min-height: 200px;
  resize: none;
}
