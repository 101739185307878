header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /* overflow: hidden; */
  width: 100%;
  z-index: 10000;
  height: 200px;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  transition: all 0.5s ease-in-out;
  background-color: var(--backgroundColor);
}

header div.qIYQfQ {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

header .logo {
  position: relative;
  color: var(--textColor);
  text-decoration: none;
  font-size: 2em;
  font-weight: 700;
  transition: 600ms;
  display: flex;
  flex-direction: row;
  align-items: center;
}
header .logo a div {
  text-transform: uppercase;
}

.header-list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-weight: 400;
}

.header-list li {
  position: relative;
  list-style: none;
}

.header-list a {
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  color: var(--textColor);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 500px;
  transition: 600ms;
}

.compact-header-list {
  position: relative;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 80px;
  margin-top: 50px;
}

.compact-header-list a {
  position: relative;
  text-decoration: none;
  color: var(--textColor);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 500px;
  transition: 600ms;
}

.navbar-sticky {
  height: 100px;
  transition: all 0.5s ease-in-out;
}

.header-menu {
  background-color: var(--backgroundColor);
  width: 100%;
  height: 0;
  position: absolute;
  left: 0;
  top: 200px;
  z-index: 10000;
  overflow: hidden;
  transition: transform 0.5s ease-in-out, height 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 6px -1px,
    rgba(0, 0, 0, 0.25) 0px 2px 4px -5px;
}

.header-menu.is-active {
  display: block;
  height: 550px;
  margin-top: auto;
}

.navbar-sticky-test {
  transform: translateY(-100px);
}

@media only screen and (max-width: 1500px) {
  .header-list a {
    position: relative;
    margin: auto;
    text-decoration: none;
    color: var(--textColor);
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 500px;
  }
}

@media only screen and (max-width: 1000px) {
  header {
    overflow: visible;
    height: 100px;
  }

  .navbar-sticky {
    height: 70px;
    transition: all 0.5s ease-in-out;
  }

  .header-menu {
    top: 100px;
  }

  .navbar-sticky-test {
    transform: translateY(-30px);
  }

  .header-list {
    display: none;
  }
}
