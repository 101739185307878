@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --backgroundColor: #0a192f;
  --textColor: white;
  --buttonColor: rgb(51, 219, 197);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding, 20vh);
}

body {
  background-color: var(--backgroundColor) !important;
  /* min-height: 200vh; */
}

.underline {
  padding: 15px 20px;
}

.underline:after {
  content: "";
  position: absolute;
  bottom: 0;
  display: block;
  height: 2px;
  left: 50%;
  background: var(--textColor);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.underline:hover:after,
.underline:focus:after,
.selected.underline:after {
  width: 100%;
  left: 0;
}

.qIYQfQ {
  max-width: 1280px;
  margin: 0 auto;
}

.wave {
  animation: wave-emoji-animation 2.5s infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-emoji-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(16deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(16deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(16deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (min-width: 1000px) {
  .qIYQfQ {
    width: 80%;
  }
}

@media (min-width: 300px) {
  .qIYQfQ {
    width: 90%;
  }
}

/* Disable default black outline on focus */
*,
*:focus,
*:hover {
  outline: none;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  transition: opacity 1.5s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform, visibility;
}

@media (max-height: 800px) {
  .fade-in-section {
    transform: translateY(10vh);
  }
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
}

/* 

*/

.curve-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  padding-top: 100px;
  background: #3c31dd;
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: hsl(216, 21%, 16%);
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #3c31dd;
  transform: translate(-4%, 40%);
  z-index: -1;
}

/* 
* Add dashes to titles
*/

.dashes-to-title {
  align-items: center;
  display: flex;
  text-align: center;
  margin-bottom: 1.5em;
}

.dashes-to-title h2 {
  order: 2;
  text-align: center;
  font-size: 36pt;
  margin: auto;
}

.dashes-to-title:before {
  flex-grow: 1;
  order: 1;
  margin-right: 0.5em;
}

.dashes-to-title:after {
  flex-grow: 1;
  order: 3;
  margin-left: 0.5em;
}

.dashes-to-title:after,
.dashes-to-title:before {
  background-color: #0bd8a2;
  content: "";
  height: 0.1em;
}

@media only screen and (min-width: 40rem) {
  .dashes-to-title:before {
    margin-right: 2rem;
  }

  .dashes-to-title::after {
    margin-left: 2rem;
  }

  /* .dashes-to-title {
    margin-bottom: 100px;
  } */
}
