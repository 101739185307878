.logo-space:before {
  content: "\00a0";
}

.logo-emphasize {
  color: rgb(51, 219, 197);
  font-size: larger;
  /* font-weight: lighter; */
}

.logo-normal {
  overflow: hidden;
  font-weight: lighter;
}

/* 
* Make the '{' and '}' lighter
*/
.logo div:first-child,
.logo div:last-child {
  font-weight: lighter;
}

.logo .rest-inner {
  margin-left: -200px;
  opacity: 0;
  transition: margin 500ms ease-in-out, opacity 500ms linear;
}

.logo:hover .rest-inner {
  margin-left: 0;
  opacity: 1;
}

@media only screen and (max-width: 500px) {
  .logo .rest-inner {
    margin-left: -200px;
    opacity: 0;
  }

  .logo div {
    font-size: 0.8em;
  }
}
