.projects {
  position: relative;
  margin-top: 200px;
  padding-top: 8rem;
  color: var(--textColor);
}

.project-portfolio .project-image-container {
  border-style: solid;
  border-width: 2px;
}

.project-grid .project-container {
  display: grid;
  gap: 2em;
  grid-template-rows: repeat(auto-fill, 500px);
  grid-auto-flow: column;
  align-items: center;
  justify-content: left;
}

.project-image-container {
  width: 600px;
  height: 400px;
  overflow: hidden;
}

.project-image-container img {
  object-fit: contain;
  height: 100%;
  border-style: none;
  border-radius: 10px;
}

.project-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-title h3 {
  max-width: 70%;
}

.project-contents a {
  font-size: 14pt;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  color: white;
}

@media (min-width: 640px) {
  .project-contents a {
    font-size: 24pt;
  }
}

.project-contents a:hover {
  color: var(--buttonColor);
}

.project-tech-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  padding: 0px;
  list-style: none;
}

.project-tech-list li {
  margin: 0px 10px 0px 0px;
  color: #a8b2d1;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
}

.project-tech-list li:hover {
  color: var(--buttonColor);
}

@media (min-width: 768px) {
  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0px 10px;
    padding: 0px;
    list-style: none;
    justify-content: center;
  }

  .project-tech-list li {
    margin: 0px 20px 5px 0px;
  }
}

.project-links a:not(:last-child) {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .project-links a:not(:last-child) {
    margin-right: 20px;
  }
}

.project-links svg {
  height: 30px;
  width: auto;
  fill: #ccd6f6;
  transition: color 0.3s ease-in-out;
}

.project-links svg:hover {
  fill: var(--buttonColor);
}

#youtube-link path {
  stroke: #ccd6f6;
}

#youtube-link:hover path {
  stroke: var(--buttonColor);
}

.project-image-container {
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.project-image-container-inner img {
  transition: 0.5s all ease-in-out;
}

.project-image-container-inner:hover img {
  transform: scale(1.2);
}

.underline-tech-list li {
  position: relative;
}

.underline-tech-list li:after {
  content: "";
  width: 0px;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0;
  display: block;
  background: var(--buttonColor);
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
.underline-tech-list li:hover:after,
.underline-tech-list li:hover:after {
  width: 100%;
  visibility: visible;
}

.project-contents h2.highlight,
span.highlight {
  color: var(--buttonColor);
}

.technology-title {
  font-weight: 600;
  font-size: 20pt !important;
}

.project-contents p {
  font-size: 12pt;
}

.project-contents p.highlight {
  font-size: 12pt;
}

.project-contents span.highlight {
  font-size: 12pt;
}

@media (min-width: 768px) {
  .project-contents p {
    font-size: 14pt;
  }

  .project-contents p.highlight {
    font-size: large;
  }

  .project-contents span.highlight {
    font-size: large;
  }
}

@media (max-width: 1000px) {
  .project-image-container {
    width: 350px;
    height: 200px;
  }

  .project-grid .project-container {
    display: grid;
    grid-template-rows: repeat(auto-fill, 200px);
    grid-auto-flow: row;
    align-items: center;
    justify-content: left;
  }
}
