.contact-me {
  position: relative;
  margin-top: 200px;
  padding-top: 8rem;
  color: var(--textColor);
  margin-bottom: 200px;
}

.contact-me form {
  width: min(700px, 100%);
  margin-inline: auto;
  margin-top: 100px;
}
